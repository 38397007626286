import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { FileControl } from 'spark-core-dx/components'
import { useCareerDomainContext } from '../CareerDomainProvider'

const Careers = () => {
  const navigate = useNavigate();

  const { jobPosts } = useCareerDomainContext();
  // console.log(jobPosts)

  // jobApplicantMgr.saveItem();

  return (
    <div className="content">
      <div className="two-part culture">
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft">
          <div className="left-text culture">
            <h1>Culture</h1>
            <p>Anyone can take orders or sling code. Minimum viable responsibility meets minimum viable output, all the while masquerading as modern technology consulting. It is a safer choice for us to operate this way, especially if we want to look like the <i>big boys.</i>​</p>
            <p>However, we choose to run fast and far away from this approach. Perhaps we care more than we should sometimes. We love to solve big problems that impact businesses. We want to be bold, resourceful, and committed for our clients. </p>
            <p>We also want to do it with style. B2B software doesn’t have to be boring, cluttered, or painful. It has to get the job done, but why can’t it work like the other apps we regularly use in our personal life? </p>
            <p>We’re all about that <i>juice</i> – to bring energy and joy – so clients can experience a better world for their customers and themselves. </p>
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight">
          <div className="culture-img-container">
            <img className="culture-img" src="/assets/Hexagon-test.png" />
          </div>
        </AnimationOnScroll>
      </div>

      {
        jobPosts ? jobPosts.map((job, index) => {
          return (
            <div onClick={() => navigate(job.UniqueId)} key={index}>
              {job.Title}
              {job.PostingFileId}
              <FileControl fileId={job.PostingFileId}/>
            </div>
          )
        }) : "Loading"
        // P & E - Product and Engineering
        // HR - People Services
      }

    </div>
  )
}

export default Careers