import { ContactUsItemService } from "./ItemServices";

export class ContactUsService extends ContactUsItemService {
    constructor(coreState, coreContext) {
        super(coreState, coreContext);
    }

    async sendMessage(contactUsItem){
        const axiosInstance = super._createAxiosInstance();
        console.log(this._urlBase +'/sendmessage');
        return await axiosInstance.post( this._urlBase +'/SendMessage', contactUsItem)
            .then(r => { return r.data; })
            .catch(e => { return super._createAxiosError(e); });
        
    }
}
