import React, { useEffect, useRef } from 'react';

const MagneticButton = ({ children }) => {
    const btnRef = useRef(null);

    useEffect(() => {
        if (btnRef && btnRef.current) {
            const btn = btnRef.current;
            btn.addEventListener('mousemove', onMouseMove);
            btn.addEventListener('mouseout', onMouseOut);
            return () => {
                btn.removeEventListener('mousemove', onMouseMove);
                btn.removeEventListener('mouseout', onMouseOut);
            }
        }
    }, [])

    const onMouseMove = (e) => {
        const btn = btnRef.current;
        const bounds = btn.getBoundingClientRect();
        const x = e.pageX - bounds.left - bounds.width / 2;
        const y = e.pageY - window.scrollY - bounds.top - bounds.height / 2;
        btn.children[0].style.transition = "unset"
        btn.style.zIndex = "1"
        btn.children[0].style.scale = "1.15"
        btn.children[0].style.transition = "scale 100ms ease-in"
        btn.children[0].style.transform = "translate(" + x * 0.3 + "px, " + y * 0.5 + "px)"
    }

    const onMouseOut = (e) => {
        const btn = btnRef.current;
        btn.style.zIndex = "0"
        btn.children[0].style.scale = "1"
        btn.children[0].style.transition = "all .5s ease-out"
        btn.children[0].style.transform = "translate(0px, 0px)"
    }

    return (
        <div ref={btnRef} className="MagneticButton">
            { children }
        </div>
    )
}
export default MagneticButton