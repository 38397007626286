import React, { useState } from 'react'
import cs from 'classnames';
import { useNavigate, useLocation } from "react-router-dom";
import useWindowDimensions from '../hooks/windowDimensions';


const Navbar = ({ direction, scrollToTop }) => {
  const [activeMenu, setActiveMenu] = useState(false);
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();


  const navigateSmoothly = (loc) => {
    // console.log(loc, pathname)
    if (loc === pathname) {
      scrollToTop(true) 
    } else {
      navigate(loc)
    }
  }

  const handleNavigate = (e) => {
    setActiveMenu(!activeMenu);
    navigateSmoothly(e);
  }

  const onMenuClick = () => {
    setActiveMenu(!activeMenu)
  }

  const logocs = cs('cdx-logo' , {
    open: direction,
    close: !direction,
  })

  const navlinkcs = cs('navlink', {
    active: direction,
    inactive: !direction,
  })

  const actmenucs = cs('overlay', {
    link: activeMenu,
    inactive: !activeMenu,
  })

  return (
    <div className="navbar">
      <a className="logo link" onClick={() => navigateSmoothly("/")}>
          <img className={width > 777 ? logocs : "cdxl"} src="/assets/cdx/CDX_Long.svg" />
        </a>
      <div className="hamburger">
        <a onClick={onMenuClick} className="mobile-logo">
          {/* <img className="cdx-bars link" src="/assets/cdx/CDX_bar.svg" /> */}
          <svg className="cdx-bars link" width="88" height="24" viewBox="0 0 45 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 6H39.7649L45 0H19.2329L14 6Z" fill="#A41034"/>
            <path d="M13.0679 9L8 15H32.9321L38 9H13.0679Z" fill="#A41034"/>
            <path d="M24.9321 24L30 18H5.06793L0 24H24.9321Z" fill="#A41034"/>
          </svg>
        </a>
        <div className={activeMenu ? "menu-box active" : "menu-box inactive"}>
          <div className="menu-header">
            <a onClick={onMenuClick} className="mobile-logo link">
              <svg className="cdx-x" width="88" height="24" viewBox="0 0 45 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="white l"  style={{animation: activeMenu ? "popOutL 0.75s ease-in-out 0.7s forwards" : "", opacity: activeMenu ? "0" : "1"}} d="M4.73535 0L10.0003 6L15.2353 0H4.73535Z" fill="white"/>
                <path className="white r"  style={{animation: activeMenu ? "popOutR 0.75s ease-in-out 0.7s forwards" : "", opacity: activeMenu ? "0" : "1"}} d="M33.7355 18.0002L29.0004 24.0002H39.0004L36.0004 20.5002L33.7355 18.0002Z" fill="white"/>
                <path className="red-bars" style={{animation: activeMenu ? "slideIn 1s ease-in-out forwards" : ""}} d="M14 6H39.7649L45 0H19.2329L14 6Z" fill="#A41034"/>
                <path className="red-bars" style={{animation: activeMenu ? "slideIn 1s ease-in-out forwards" : ""}} d="M13.0679 9L8 15H32.9321L38 9H13.0679Z" fill="#A41034"/>
                <path className="red-bars" style={{animation: activeMenu ? "slideIn 1s ease-in-out forwards" : ""}} d="M24.9321 24L30 18H5.06793L0 24H24.9321Z" fill="#A41034"/>
              </svg>
            </a>
          </div>
          <div className="menu-body">
            <ul className="menu-links" >
              <li onClick={() => handleNavigate("/")}>
                <a className={`menu-link link ${pathname === "/" && "selected"}`} onClick={() => handleNavigate("/")}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3zm5 15h-2v-6H9v6H7v-7.81l5-4.5 5 4.5V18z"/><path d="M7 10.19V18h2v-6h6v6h2v-7.81l-5-4.5z" opacity=".3"/></svg>
                  Home
                </a>
              </li>
              <li onClick={() => handleNavigate("/manifesto")}>
                <a className={`menu-link link ${pathname === "/manifesto" && "selected"}` } onClick={() => handleNavigate("/manifesto")}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><rect fill="none" height="24" width="24"/></g><g><g><g opacity=".3"><path d="M11.34,9.76L9.93,8.34C8.98,7.4,7.73,6.88,6.39,6.88C5.76,6.88,5.14,7,4.57,7.22l1.04,1.04h2.28v2.14 c0.4,0.23,0.86,0.35,1.33,0.35c0.73,0,1.41-0.28,1.92-0.8L11.34,9.76z"/></g><g opacity=".3"><path d="M11,6.62l6,5.97V14h-1.41l-2.83-2.83l-0.2,0.2c-0.46,0.46-0.99,0.8-1.56,1.03V15h6v2c0,0.55,0.45,1,1,1s1-0.45,1-1V6h-8 V6.62z"/></g><g><path d="M9,4v1.38c-0.83-0.33-1.72-0.5-2.61-0.5c-1.79,0-3.58,0.68-4.95,2.05l3.33,3.33h1.11v1.11c0.86,0.86,1.98,1.31,3.11,1.36 V15H6v3c0,1.1,0.9,2,2,2h10c1.66,0,3-1.34,3-3V4H9z M7.89,10.41V8.26H5.61L4.57,7.22C5.14,7,5.76,6.88,6.39,6.88 c1.34,0,2.59,0.52,3.54,1.46l1.41,1.41l-0.2,0.2c-0.51,0.51-1.19,0.8-1.92,0.8C8.75,10.75,8.29,10.63,7.89,10.41z M19,17 c0,0.55-0.45,1-1,1s-1-0.45-1-1v-2h-6v-2.59c0.57-0.23,1.1-0.57,1.56-1.03l0.2-0.2L15.59,14H17v-1.41l-6-5.97V6h8V17z"/></g></g></g></svg>
                  Manifesto
                </a>
              </li>
              {/* <li onClick={() => handleNavigate("/showcase")}>
                <a className={`menu-link link ${pathname === "/showcase" && "selected"}`} onClick={() => handleNavigate("/showcase")}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><rect fill="none" height="24" width="24"/><path d="M12,4c-3.31,0-6,2.69-6,6s2.69,6,6,6s6-2.69,6-6S15.31,4,12,4z M14.31,13.69L12,11.93l-2.32,1.76l0.88-2.85 L8.25,9h2.84L12,6.19L12.91,9h2.84l-2.32,1.84L14.31,13.69z M12,19l-4,1.02v-3.1C9.18,17.6,10.54,18,12,18s2.82-0.4,4-1.08v3.1 L12,19z" opacity=".3"/><path d="M9.68,13.69L12,11.93l2.31,1.76l-0.88-2.85L15.75,9h-2.84L12,6.19L11.09,9H8.25l2.31,1.84L9.68,13.69z M20,10 c0-4.42-3.58-8-8-8s-8,3.58-8,8c0,2.03,0.76,3.87,2,5.28V23l6-2l6,2v-7.72C19.24,13.87,20,12.03,20,10z M12,4c3.31,0,6,2.69,6,6 s-2.69,6-6,6s-6-2.69-6-6S8.69,4,12,4z M12,19l-4,1.02v-3.1C9.18,17.6,10.54,18,12,18s2.82-0.4,4-1.08v3.1L12,19z"/></svg>
                  Showcase
                </a>
              </li>
              <li onClick={() => handleNavigate("/careers")}>
                <a className={`menu-link link ${pathname === "/careers" && "selected"}`} onClick={() => handleNavigate("/careers")}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><g><path d="M13,16h-2c-1.65,0-3,1.35-3,3v1h8v-1C16,17.35,14.65,16,13,16z M12,19c-0.55,0-1-0.45-1-1s0.45-1,1-1 s1,0.45,1,1S12.55,19,12,19z" opacity=".3"/><path d="M16.13,15.13L18,3h-4V2h-4v1H5C3.9,3,3,3.9,3,5v4c0,1.1,0.9,2,2,2h2.23l0.64,4.13C6.74,16.05,6,17.43,6,19v1 c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2v-1C18,17.43,17.26,16.05,16.13,15.13z M5,9V5h1.31l0.62,4H5z M15.67,5l-1.38,9H9.72L8.33,5 H15.67z M16,20H8v-1c0-1.65,1.35-3,3-3h2c1.65,0,3,1.35,3,3V20z"/><circle cx="12" cy="18" r="1"/></g></g></svg>
                  Careers
                </a>
              </li> */}
              <li onClick={() => handleNavigate("/contact")}>
                <a className={`menu-link link ${pathname === "/contact" && "selected"}`} onClick={() => handleNavigate("/contact")}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 8l-8 5-8-5v10h16zm0-2H4l8 4.99z" opacity=".3"/><path d="M4 20h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2zM20 6l-8 4.99L4 6h16zM4 8l8 5 8-5v10H4V8z"/></svg>
                  Contact
                </a>
              </li>
            </ul>
            <div className="menu-box-contact">
              <ul>
                <li>Contact Us!</li>
                <li><a href="mailto:contact@civicdx.com">contact@civicdx.com</a></li>
                <li><a href="tel:4048498180">(404) 849-8180</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div onClick={activeMenu ? onMenuClick : null} className={actmenucs} />
      </div>
        {/* <a className="logo link" onClick={() => navigateSmoothly("/")}>
          <img className={logocs} src="/assets/cdx/CDX_Long.svg" />
        </a> */}
        <div className="navbar-block">
            <a className={navlinkcs} onClick={() => navigateSmoothly("/manifesto")}>Manifesto</a>
            {/* <a className={navlinkcs} onClick={() => navigateSmoothly("/showcase")}>Showcase</a>
            <a className={navlinkcs} onClick={() => navigateSmoothly("/careers")}>Careers</a> */}
            <a className="btn nav-btn link v-hover" onClick={() => navigateSmoothly("/contact")}>Get Started</a>
            {/* {pathname === "/culture" ? 
            <a className="btn nav-btn link db-hover" href="https://www.linkedin.com/company/civicdx/jobs/" target="_blank">Join Us</a> :
            <a className="btn nav-btn link v-hover" onClick={() => navigateSmoothly("/contact")}>Get Started</a>
            } */}
            {/* <a className="btn nav-btn link" style={!activeMenu ? {visibility: "hidden"} : null} onClick={() => navigateSmoothly("/contact")}>Get Started</a> */}
        </div>
    </div>
  )
}

export default Navbar