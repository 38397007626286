import React, { useState, useEffect, useRef } from 'react'
import cs from 'classnames'; 
import { PopupButton } from "react-calendly"
import { useCoreStateContext, useCoreContext } from 'spark-core-dx/contexts'
import { ContactUsService } from '../../../services/ContactUsService';

const Contact = () => {
  const [valid, setValid] = useState({    
    Email: [false, 0, false],
    FullName: [false, 0, false],
    Phone: [true, 0, false],
    Message: [false, 0, false]
  });
  const sideForm1 = useRef(null);
  const sideForm2 = useRef(null);
  const [formHeight, setFormHeight] = useState(null);
  const [formHeight2, setFormHeight2] = useState(null);
  
  const [contactUs,setContactUs] = useState({});
  const [messageSent, setMessageSent] = useState(null);
  const [sent, setSent] = useState(false);
  const sentcs = cs('cf-form', {
    sent: sent,
  })

  useEffect(() => {
    if (!sideForm1.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setFormHeight(sideForm1.current.offsetHeight)
    });
    resizeObserver.observe(sideForm1.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, []);

  useEffect(() => {
    if (!sideForm2.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setFormHeight2(sideForm2.current.offsetHeight)
    });
    resizeObserver.observe(sideForm2.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, []);

  const coreStateContext = useCoreStateContext();
  const coreContext = useCoreContext();
  const sendEmail = async ()=>{
    const validateForm = Object.values(valid).every(val => val[0] === true);
    if (validateForm) {
      setSent(true);
      // setMessageSent(<p className="result-message centered">Apologies, an error has occured while sending the message. <br /> Please refresh the page to try again.</p>)
      try {
        const contactUsSvc = new ContactUsService(coreStateContext,coreContext);
        const results = await contactUsSvc.sendMessage(contactUs);
        if(results.first().Success){
          setSent(true);
          setMessageSent(<p className="result-message centered">Message has been sent.</p>);
        }
        else {
          setMessageSent(<p className="result-message centered">Apologies, an error has occured while sending the message. <br /> Please refresh the page to try again.</p>);
        }
      } catch (error) {
        console.log(error)
        setMessageSent(<p className="result-message centered">Apologies, an error has occured while sending the message. <br /> Please refresh the page to try again.</p>);
      }
    } else {
      var updatedValid = {...valid};
        Object.entries(valid).map(([key]) => {
          if (!updatedValid[key][0]) {
            updatedValid[key][2] = true;
          }
      })
      setValid(updatedValid)
    }
  }

  const validator = (regex, fieldName, val) => {
    var updatedValid = {...valid};
    updatedValid[fieldName][0] = (regex.test(val));
    updatedValid[fieldName][1] = val.length;
    updatedValid[fieldName][2] = true;
    // console.log(val, regex.test(val))
    setValid(updatedValid);
    // console.log(valid)
  }

  const handleOnChange = (fieldName, val) => {
    switch (fieldName) {
      case "Email":
        const emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        validator(emailRe, fieldName, val)
        break;
      case "FullName":
        const nameRe = /^[a-zA-Z]+/;
        validator(nameRe, fieldName, val)
        break;
      case "Phone":
        const numberRe = /(^$)|^[0-9\b]{10,12}$/;
        validator(numberRe, fieldName, val)
        break;
      case "Message":
        const messageRe = /^(?=.*[a-zA-Z0-9]).{1,}$/;
        validator(messageRe, fieldName, val)
        break;
    }

    var updatedContactUs = {...contactUs};
    updatedContactUs[fieldName] = val;
    setContactUs(updatedContactUs);
  }

  const handleData = (fieldName, message) => {
    const type = valid[fieldName];
    if (fieldName !== "Phone") {
      return (
        type[1] === 0 ? type[2] && <span className="error">{message}</span>:
        <span className={!type[0] ? "error" : "error-none"}>{message}</span> )
    } else {
      return (
        type[1] > 0 && type[2] && <span className={!type[0] ? "error" : "error-none"}>{message}</span> )
    }
  }

  return (
    <>
    <div className="content contact-content">
      <div className="left-text">
          <h1>Contact Us</h1>          
          <p>Let us know how we can be of service.</p>
          <p>We'd love to learn more about you!</p>
      </div>

      <div className={sentcs} style={{height: sent ? "16rem" : `calc(2rem + ${formHeight + formHeight2}px`, transition: sent ? "height 1s" : null}}>
        <div className="cf-wrapper centered">
          <article className="cf">
            <div ref={sideForm1} className="side form">
              <div className="form-label">
                <label>Email<span class="is-required-asterik">*</span></label>
              </div>
              {handleData("Email", "Hmmm. Seems like perhaps you're missing something. Is this email addresss correct?")}
              <input type="text" onChange={(e)=>handleOnChange("Email", e.target.value)} name="email" />
              <div className="form-label">
                <label>Name<span class="is-required-asterik">*</span></label>
              </div>
              {handleData("FullName", `Let us know your name as we'd love to avoid addressing you as "Hey You"`)}
              <input type="text" onChange={(e)=>handleOnChange("FullName", e.target.value)} name="name" />
            </div>
            
            <div ref={sideForm2} className="side form">
              <div className="form-label">
                <label>Phone Number</label>
              </div>
              {handleData("Phone", "Please enter your phone number so that we may follow-up directly. Fear not, we never let robots near the phones.")}
              <input type="text"  onChange={(e)=>handleOnChange("Phone", e.target.value)} name="phone" />
              
              <div className="form-label">
                  <label>Message<span class="is-required-asterik">*</span></label> 
                </div>
                {handleData("Message", "In a few words, how we can help you?")}
                <textarea name="message" onChange={(e)=>handleOnChange("Message", e.target.value)} />
                <div className="submit-container">
                  <input className="submit btn db-hover" type="button" onClick={sendEmail} value="Submit Form" />
                </div>
              </div>

          </article>
          <div className="envelope front"></div>
          <div className="envelope back"></div>
        </div>
        {messageSent}
        {/* <p className="result-message centered">{messageSent}</p> */}
      </div>
      
        <div className="left-text contact-bottom">
          <p>If you'd rather schedule some time to speak directly with a team member, <br />click the button below to schedule a time!</p>
        </div>
        <div className="button-container">
          <PopupButton className="btn contact v-hover" rootElement={document.getElementById("root")} 
              text={<div>Schedule a Meeting<img src="/assets/arrows/arrows-right.svg" /></div>} 
              url="https://calendly.com/wearecivicdx/connect" />
        </div>
      </div>


    </>
  )
}

export default Contact