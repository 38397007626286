const casestudy = [
    {
        title: "Dover Custom Builders",
        subtitle: "Digital Refresh for a Luxury Home Builder",
        image: "/assets/casestudy/dcb_card.jpg",
        theme: "dark",
        line: "Website, Portal, Infrastructure to Transform Customer Experience for Luxury Home Builder",
        text: [<p>A configurable enterprise dashboard, Mindbody Insights was the natural evolution of the mobile analytics platform named ZeeZor which caught fire in the salon and spa industry as the standard for employee engagement and real-time reporting.</p>, <img src="/assets/casestudy/zeezor1.png" />, <p>ZeeZor recruited our team to redesign this proven product which led to a reimagined brand and transformed platform that fueled new strategic partnerships and customer acquisition opportunities, as well as an eventual acquisition by Mindbody (owned by Vista Equity Partners).​</p>, <img src="/assets/casestudy/zeezor2.jpg" />],
        // text: "A boutique custom home builder, Adam C. Dover, has made a strong impression in the minds of homeowners for over two decades with his craftsmanship and work ethic. That said, his digital presence did not match the polish that his homes had been known for. Civic DX retold his story as he sought to refresh and reposition his brand and company, while also serving his clients through story and a new online portal.",
        showcaseId: "dover-custom-builders",
        info: [{
            head: "Services",
            items: [
                "Discovery", "Design", "Development", "Infrastructure"
            ]
        },
        {
            head: "Product Type",
            items: [
                "Branding", "Website", "Builder Management System", "Digital Marketing"
            ]
        },
        {
            head: "Industry",
            items: [
                "Construction"
            ]
        },
        {
            head: "Expertise",
            items: [
                "Adobe CC", "UX/UI", "React", "Integration", "Azure Cloud Migration", "Microsoft 365"
            ]
        },
        {
            head: "Year(s)",
            items: [
                "2021-2022"
            ]
        }]
    },
    {
        title: "SideKick Finance",
        subtitle: "Financial Platform for Web3 Ecosystem",
        image: "/assets/casestudy/sk_card.jpg",
        theme: "dark",
        line: "Design and Development of a Platform to Fuel Web3 Ecosystem Adoption and Advocacy",
        text: [<p>A configurable enterprise dashboard, Mindbody Insights was the natural evolution of the mobile analytics platform named ZeeZor which caught fire in the salon and spa industry as the standard for employee engagement and real-time reporting.</p>, <img src="/assets/casestudy/zeezor1.png" />, <p>ZeeZor recruited our team to redesign this proven product which led to a reimagined brand and transformed platform that fueled new strategic partnerships and customer acquisition opportunities, as well as an eventual acquisition by Mindbody (owned by Vista Equity Partners).​</p>, <img src="/assets/casestudy/zeezor2.jpg" />],
        // text: "SideKick Finance in 2021 established itself as a player in the defi crypto currency space with fun and powerful data tools. However, a strategic pivot into the crowdfunding ecosystem created an opportunity for Civic DX to lead the design and development of this new digital marketplace called JumpStart.",
        showcaseId: "sidekick",
        info: [{
            head: "Services",
            items: [
                "Design", "Development", "Care & Growth"
            ]
        },
        {
            head: "Product Type",
            items: [
                "Web App", "Website", "Digital Marketing", "Event Marketing"
            ]
        },
        {
            head: "Industry",
            items: [
                "Finance", "Web3"
            ]
        },
        {
            head: "Expertise",
            items: [
                "Adobe CC", "Figma", "UX/UI", "React", "Integration", "Analytics", "Reporting", "Smart Contracts"
            ]
        },
        {
            head: "Year(s)",
            items: [
                "2021-2022"
            ]
        }]
    },
    {
        title: "Vertice AI",
        subtitle: "AI/ML Marketing Platform for Credit Unions",
        image: "/assets/casestudy/vai_card.jpg",
        theme: "dark",
        line: "AI-powered Analytics Platform for Next Gen Credit Union Member Experience",
        text: [<p>A configurable enterprise dashboard, Mindbody Insights was the natural evolution of the mobile analytics platform named ZeeZor which caught fire in the salon and spa industry as the standard for employee engagement and real-time reporting.</p>, <img src="/assets/casestudy/zeezor1.png" />, <p>ZeeZor recruited our team to redesign this proven product which led to a reimagined brand and transformed platform that fueled new strategic partnerships and customer acquisition opportunities, as well as an eventual acquisition by Mindbody (owned by Vista Equity Partners).​</p>, <img src="/assets/casestudy/zeezor2.jpg" />],
        // text: "Vertice AI captured the power AI/ML to usher in a new era of data-driven marketing analytics and strategic campaign engineering for the Credit Union industry. As this tech start-up came to market in 2023, Civic DX was able to not only design, develop, and deploy their platform, but we put into motion a foundation for their software-as-a-service (SaaS) operations as this platform included unique accounts, user management, and roles & permissions configuration to allow for client-specific experiences as they grow. Vertice AI is empowering the credit union movement to personalize the member experience.",
        showcaseId: "vertice-ai",
        info: [{
            head: "Services",
            items: [
                "Design", "Development"
            ]
        },
        {
            head: "Product Type",
            items: [
                "Web App", "SaaS"
            ]
        },
        {
            head: "Industry",
            items: [
                "AI/ML", "Finance", "Marketing"
            ]
        },
        {
            head: "Expertise",
            items: [
                "Figma", "UX/UI", "React", "Platform Management", "Reporting", "Analytics"
            ]
        },
        {
            head: "Year(s)",
            items: [
                "2022-2023"
            ]
        }]
    },
    {
        title: "ZeeZor",
        subtitle: "Real-time Mobile Analytics for Salons & Spas",
        image: "/assets/casestudy/zz_card.jpg",
        theme: "dark",
        line: "Platform Development to Drive Data-Driven Success in Health & Wellness Industry",
        text: [<p>A configurable enterprise dashboard, Mindbody Insights was the natural evolution of the mobile analytics platform named ZeeZor which caught fire in the salon and spa industry as the standard for employee engagement and real-time reporting.</p>, <img src="/assets/casestudy/zeezor1.png" />, <p>ZeeZor recruited our team to redesign this proven product which led to a reimagined brand and transformed platform that fueled new strategic partnerships and customer acquisition opportunities, as well as an eventual acquisition by Mindbody (owned by Vista Equity Partners).​</p>, <img src="/assets/casestudy/zeezor2.jpg" />],
        // text: "A configurable enterprise dashboard, Mindbody Insights was the natural evolution of the mobile analytics platform named ZeeZor which caught fire in the salon and spa industry as the standard for employee engagement and real-time reporting. ZeeZor recruited our team to redesign this proven product which led to a reimagined brand and transformed app platform that fueled new strategic partnerships and customer acquisition opportunities, as well as an eventual acquisition by Mindbody.",        
        showcaseId: "zeezor",
        info: [{
            head: "Services",
            items: [
                "Design", "Development", "Care & Growth"
            ]
        },
        {
            head: "Product Type",
            items: [
                "Branding", "Mobile App", "Web App", "SaaS"
            ]
        },
        {
            head: "Industry",
            items: [
                "Health & Wellness"
            ]
        },
        {
            head: "Expertise",
            items: [
                "Adobe CC", "UX/UI", "React", "Azure Cloud Migration", "MongoDB", "Snowflake", "Integration", "iOS", "Android"
            ]
        },
        {
            head: "Year(s)",
            items: [
                "2018-2021"
            ]
        }]
    },
    {
        title: "ABM",
        subtitle: "Strategic Tech Roadmap Design for Industrial Franchise",
        image: "/assets/casestudy/abm_card.jpg",
        theme: "dark",
        line: "Architectural Solution & Roadmap Design for Industrial Franchise Tech Renaissance",
        text: [<p>A configurable enterprise dashboard, Mindbody Insights was the natural evolution of the mobile analytics platform named ZeeZor which caught fire in the salon and spa industry as the standard for employee engagement and real-time reporting.</p>, <img src="/assets/casestudy/zeezor1.png" />, <p>ZeeZor recruited our team to redesign this proven product which led to a reimagined brand and transformed platform that fueled new strategic partnerships and customer acquisition opportunities, as well as an eventual acquisition by Mindbody (owned by Vista Equity Partners).​</p>, <img src="/assets/casestudy/zeezor2.jpg" />],
        // text: "Our CEO, Alan Dandar, recently presented on the future of franchise technology alongside Karen Clark, the Director of Franchise Technology for the ABM Franchising Group, at ABM's annual Continuing Education (ConEd) Conference in Atlanta, Georgia. A special thanks to Karen and her team for being amazing partners in innovation and allowing us to co-develop such a bold vision for the future! The team at Civic DX spent 4 months investigating the technological needs of the franchisees by meeting with 20+ franchisees, both onsite and virtually, as well as vetting a vast array of current and prospective software vendors. The team then went on to develop a detailed solution architecture document and implementation roadmap which was presented by Alan and Karen at ConEd to ABM's franchisee principals as well as their key staff members.",        
        showcaseId: "abm",
        info: [{
            head: "Services",
            items: [
                "Discovery", "Design"
            ]
        },
        {
            head: "Product Type",
            items: [
                "Analysis", "Strategic Roadmap", "Public Speaking"
            ]
        },
        {
            head: "Industry",
            items: [
                "HVAC", "Franchising"
            ]
        },
        {
            head: "Expertise",
            items: [
                "Focus Group Moderation", "Architecture Design", "Strategic Planning", "Survey Analysis", "Video Production"
            ]
        },
        {
            head: "Year(s)",
            items: [
                "2023-2024"
            ]
        }]
    },
    {
        title: "NIET",
        subtitle: "Content & Training Portal for Educators",
        image: "/assets/casestudy/niet_card.jpg",
        theme: "dark",
        line: "Design & Development of Training Platforms to Build Educator Excellence",
        text: [<p>A configurable enterprise dashboard, Mindbody Insights was the natural evolution of the mobile analytics platform named ZeeZor which caught fire in the salon and spa industry as the standard for employee engagement and real-time reporting.</p>, <img src="/assets/casestudy/zeezor1.png" />, <p>ZeeZor recruited our team to redesign this proven product which led to a reimagined brand and transformed platform that fueled new strategic partnerships and customer acquisition opportunities, as well as an eventual acquisition by Mindbody (owned by Vista Equity Partners).​</p>, <img src="/assets/casestudy/zeezor2.jpg" />],
        showcaseId: "niet",
        info: [{
            head: "Services",
            items: [
                "Design", "Development", "Care & Growth"
            ]
        },
        {
            head: "Product Type",
            items: [
                "Web App", "SaaS"
            ]
        },
        {
            head: "Industry",
            items: [
                "Education"
            ]
        },
        {
            head: "Expertise",
            items: [
                "Adobe CC", "Figma", "UX/UI", "React", "Azure", "Integration", "Media Management"
            ]
        },
        {
            head: "Year(s)",
            items: [
                "2021-2024"
            ]
        }]
    },
    {
        title: "Superior Land Design",
        subtitle: "Brand & Web Reboot for Pipeline Services Company",
        image: "/assets/casestudy/sld_card.jpg",
        theme: "dark",
        line: "Branding & Digital Refresh for Pipeline Safety & Surveying Company",
        text: [<p>A configurable enterprise dashboard, Mindbody Insights was the natural evolution of the mobile analytics platform named ZeeZor which caught fire in the salon and spa industry as the standard for employee engagement and real-time reporting.</p>, <img src="/assets/casestudy/zeezor1.png" />, <p>ZeeZor recruited our team to redesign this proven product which led to a reimagined brand and transformed platform that fueled new strategic partnerships and customer acquisition opportunities, as well as an eventual acquisition by Mindbody (owned by Vista Equity Partners).​</p>, <img src="/assets/casestudy/zeezor2.jpg" />],
        // text: "An impactful player in the petroleum and natural gas pipeline construction industry, Superior Land Designs (SLD) has brought a range of construction and safety training services for over 20 years. Civic DX worked alongside SLD leadership to redevelop their branding as well as website to reflect the evolving capabilities and customer value to improve their customer acquisition strategy as well as marketplace presence.",        
        showcaseId: "sld",
        info: [{
            head: "Services",
            items: [
                "Discovery", "Design", "Development", "Care & Growth"
            ]
        },
        {
            head: "Product Type",
            items: [
                "Branding", "Website"
            ]
        },
        {
            head: "Industry",
            items: [
                "Construction", "Petroleum & Natural Gas"
            ]
        },
        {
            head: "Expertise",
            items: [
                "Adobe CC", "Figma", "UX/UI", "React", "Azure Cloud Migration", "Microsoft 365"
            ]
        },
        {
            head: "Year(s)",
            items: [
                "2023-2024"
            ]
        }]
    }
]

export default casestudy;