import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash';
import { FormControl, FieldControl, ButtonControl } from 'spark-core-dx/components'
import { useCareerDomainContext } from '../CareerDomainProvider';
import * as DOMPurify from 'dompurify';

const Job = () => {
    const { jobId } = useParams();

    const {jobPosts, jobApplicantMgr, jobApplicantItem, setJobApplicantItem, jobApplicantMetadata} = useCareerDomainContext();
    const getJob = jobPosts?.find((post) => post.UniqueId === jobId)

    // console.log(jobPosts)
    const [questionsArray, setQuestionsArray] = useState(null);

    useEffect(() => {
      try {
        if (getJob) {
          setQuestionsArray(getJob?.Questions.map((q) => {
            return {
              Question: q.Question,
              Answer: null,
              ExpectedAnswer: q.ExpectedAnswer,
              QuestionId: q._id,
            }
          }))
        }
      } catch (e) {
        console.log(e)
      }
      
    }, [getJob])
  
    const handleOnChange = (value, fieldName) => {
      setJobApplicantItem(prev => {
        return {...prev, [fieldName]: value}
      })
    }

    const handleQuestionOnChange = (question, value) => {
      const copied = [...questionsArray]
      const copiedQuestion = copied.find((q) => q.Question === question)
      const ind = copied.findIndex((q) => q.Question === question)
      copiedQuestion.Answer = value
      copied.splice(ind, 1, copiedQuestion)
      // console.log(copied)
      setQuestionsArray(copied)
    }

    // useEffect(() => {
    //   console.log(jobApplicantItem)
    // }, [jobApplicantItem])

    const renderQuestions = () => {
      // format: QuestionAnswer: [{q: q, a:a}, {q: q, a:a}]
      // console.log(jobApplicantMgr.uiMetadata.find(y => y.Name === "QuestionAnswer").Fields.find(y => y.FieldName === "Answer"))
      return questionsArray?.map((x) => {
        return <div className="qa">
          <div className="question">
            {x.Question}
          </div>
          <FieldControl key={x.Question} className="answer" fieldKey={x.Question} onChange={(v) => handleQuestionOnChange(x.Question, v)} 
          value={questionsArray?.find((q) => q?.Question === x.Question).Answer} 
          fieldMetadata={jobApplicantMgr.uiMetadata.find(y => y.Name === "QuestionAnswer").Fields.find(y => y.FieldName === "Answer")} />
        </div>
    })
    }
  
    const renderApplication = () => {
      return _.orderBy(jobApplicantMgr.uiMetadata.first().Fields, ["DisplaySequence"], ["asc"]).filter(x => x.ShowOnPublicForm).map((x) => {
        return <FieldControl onChange={handleOnChange} value={jobApplicantItem[x.FieldName]} fieldMetadata={x} />
      })
    }

    const [save, setSave] = useState(false);

    useEffect(() => {
      if (save) {
        // console.log(jobApplicantItem)
        jobApplicantMgr.saveItem(jobApplicantItem)
      }
    }, [save])

    const handleSubmit = () => {
      if (questionsArray) {
        setJobApplicantItem(prev => {
          return {...prev, ["QuestionAnswers"]: questionsArray, JobPostingId: jobId}
        })
      }
      setSave(true)
      // console.log({...jobApplicantItem})
    }
    
    // jobApplicantMgr.saveItem();
  
  return (
    <div>
        {getJob && jobApplicantMetadata && jobApplicantMgr.uiMetadata ?
        <>
          <div className="job-listing">
            <div className="job-header">
              <h1>{getJob?.Title}</h1>
              <h2>{getJob.JobCategory} - {getJob.Location}</h2>
            </div>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getJob?.PostingContent, { sanitize: true })}} />
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getJob?.Requirements, { sanitize: true })}} />
            <div className="salary">
              <h3><strong>Salary</strong></h3>
              <p>${Number(getJob.SalaryRangeFrom).toLocaleString()}&nbsp;-&nbsp;${Number(getJob.SalaryRangeTo).toLocaleString()}</p>
            </div>
            {/* <p>Projected Start Date: {getJob.ProjectStartDate}</p> */}

            <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(getJob?.AdditionalContent, { sanitize: true })}} />
          </div>
           
          <div className="job-application">
            <div className="job-application-header">
              <h1>Apply for this Job &nbsp;&nbsp;👇</h1>
            </div>
            <hr/>
            {renderApplication()}
            <div className="questions-container">
              {renderQuestions()}
            </div>
            <ButtonControl onClick={handleSubmit}>Submit</ButtonControl>
          </div>
        </> : "loading..."
            }

    </div>
  )
}

export default Job