import React, { createContext, useState, useEffect, useContext } from 'react';
import { useGenericManager, useDefaultObject } from 'spark-core-dx/hooks'
import { ItemServiceFactory as MarketingItemServiceFactory } from '../../services/ItemServiceFactory';
import { useCoreStateContext, useCoreContext } from 'spark-core-dx/contexts';

export const CareerDomainContext = createContext();

export function CareerDomainContextProvider({ children }) {
    const itemService = new MarketingItemServiceFactory()
    const jobPostingMgr = useGenericManager(itemService.ItemServiceEnum.JobPosting, itemService);
    const jobApplicantMgr = useGenericManager(itemService.ItemServiceEnum.JobApplicant, itemService);
  
    const { applicationStatus } = useCoreContext();
    const coreState = useCoreStateContext();
    const transformFieldsToObject = useDefaultObject();
    const [jobApplicantMetadata, setJobApplicantMetadata] = useState(null)
    const [jobApplicantItem, setJobApplicantItem] = useState(null)
  
    const [jobPosts, setJobPosts] = useState(null)
  
    useEffect(() => {
      if (applicationStatus === 2) {
        jobPostingMgr.search({ searchModel: "x => x.IsActive == true && x.IsDeleted == false" }).then(r => {
          setJobPosts(r)
        })
        jobApplicantMgr.GetMetadata({}).then(r => {
            // console.log(r)
          setJobApplicantMetadata(r.Items.first())
          setJobApplicantItem(transformFieldsToObject(r.Items.first().Fields))
        })
      }
    }, [applicationStatus])
    
    useEffect(() => {
        return () => {
            coreState.clear()
        }
    }, [])

    return (
        <CareerDomainContext.Provider value={{jobPosts, jobPostingMgr, jobApplicantMgr, jobApplicantItem, jobApplicantMetadata, setJobApplicantItem}}>
            {children}
        </CareerDomainContext.Provider>

    );
}
export const useCareerDomainContext = () => useContext(CareerDomainContext);