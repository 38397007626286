const reviews = [ 
    {
        name: "Mitch Rutledge",
        title: "Founder and CEO",
        company: "Vertice AI",
        img: "assets/headshots/mitchrutledge.jpg",
        logo: "/assets/logos/vertice-ai.svg",
        quote: `The team at Civic is more than a development partner.  For us, they brought strategic insight and real-world perspective about how to create a great product.  They consistently demonstrated a passion to understand our vision and what could make our offering unique.  Their whole team was not afraid to challenge us in our thinking.  They were organized, always on time with deliverables and had a great team that was completely professional from beginning to end.`,
    },
    {
        name: "Chris Nedza",
        title: "Founder and CEO",
        company: "ZeeZor",
        img: "/assets/headshots/chrisnedza.jpg",
        logo: "/assets/logos/zeezor_raw.svg",
        quote: `I’ve known and worked with the Civic DX team for close to 10 years. I first brought them into a ridiculously challenging situation which without their help would have resulted in the failure of the company. They not only saved the day (literally), they never missed a deadline and executed with excellence and way beyond the call of duty. We turned around the company, scaled it and ultimately had a nice exit - I’ve enthusiastically recommended them to others - they know what they’re doing!`,
    },
    {
        name: "Tyler Jones",
        title: "Founder and CEO",
        company: "SideKick Finance",
        img: "/assets/headshots/tylerjones.jpg",
        logo: "/assets/logos/sidekick.svg",
        quote: `Civic DX was instrumental in bringing our enterprise-ready website and NFT crowdfunding platform to life. Their expertise, dedication, and ability to turn complex ideas into user-friendly experiences were outstanding. Civic DX’s blend of traditional web development and innovative NFT solutions exceeded our expectations, delivering a robust, scalable platform. Their commitment to client success is evident, making them an invaluable partner in our digital journey. Highly recommended – these guys truly rock!`,
    },
    {
        name: "Adam Dover",
        title: "Owner",
        company: "Dover Custom Builders",
        img: "/assets/headshots/adamdover1.jpg",
        logo: "/assets/logos/dovercustom.png",
        quote: `Let me start by saying thank you. I have had the pleasure to meet Alan Dandar by chance. He contacted me for some help with designing a house for his family. While this process was underway, Alan did Alan, and was looking for a way to make my online business presence better. Alan took our conversations and my company’s actions while engaged with his family home to reimagine my digital customer experience. Ultimately, the team at Civic DX then came back to collaborate and deliver in big ways. Thank you again and I will keep sending business your way.`,
    },
    {
        name: "Josh Barbieri",
        company: "Improving",
        title: "Consulting Director",
        img: "/assets/headshots/joshbarbieri.jpg",
        logo: "/assets/logos/improving.svg",
        quote: `I had the great pleasure to have Civic DX as our technology vendor for a seven-figure web application 
        that was the strategic lynchpin for a non-profit education client's customer engagement platform. 
        Alan, Ryan and the team did an incredible job of the design and delivery of the project - 
        with stellar attention to detail, and a thorough mindfulness in quality assurance process to highly functional delivery. 
        Alan and Ryan both value high quality development and very satisfied customers.  
        If you are looking for a team to deliver expectational quality with to your next web application project, choose Civic DX.`,
    },
    {
        name: "Mark Fikse",
        title: "Founder and CEO",
        company: "SkyBeam Capital",
        img: "/assets/headshots/markfikse.jpg",
        logo: "/assets/logos/skybeam-capital.svg",
        quote: `The Civic DX team was instrumental in helping us launch the IT infrastructure when we were getting SkyBeam Capital off the ground.  Their deep technical expertise along with their responsiveness helped us integrate a variety of technical solutions during our initial years of growth.`,
    },
    // {
    //     name: "Karen Clark",
    //     title: "Director of Franchise Technology",
    //     company: "ABM",
    //     img: "/assets/headshots/adamdover1.jpg",
    //     logo: "/assets/logos/abm1.svg",
    //     quote: `I had the great pleasure to have Civic DX as our technology vendor for a seven-figure web application 
    //     that was the strategic lynchpin for a non-profit education client's customer engagement platform. 
    //     Alan, Ryan and the team did an incredible job of the design and delivery of the project - 
    //     with stellar attention to detail, and a thorough mindfulness in quality assurance process to highly functional delivery. 
    //     Alan and Ryan both value high quality development and very satisfied customers.  
    //     If you are looking for a team to deliver expectational quality with to your next web application project, choose Civic DX.`,
    // },
    // {
    //     name: "Brandon Wilhite",
    //     title: "Sr. Manager of Software Development",
    //     company: "Capstone Logistics",
    //     img: "/assets/headshots/adamdover1.jpg",
    //     logo: "/assets/logos/capstone-logistics.svg",
    //     quote: `Capstone`,
    // },
    // {
    //     name: "Patrick Dover",
    //     title: "Owner",
    //     company: "Superior Land Designs",
    //     img: "/assets/headshots/adamdover1.jpg",
    //     logo: "/assets/logos/sld.svg",
    //     quote: `SLD`,
    // },
]

export default reviews;