import React from 'react'

const DropLetters = ({text}) => {
  return (
    <div className="drop-animation-container">
        {/* {text.split(" ").map((word, index) => {
            return (
            <div className="drop-words" key={index} style={{animation: `drop-anim 1s ease-in-out ${index * 100}ms forwards`}}> 
                {word}
            </div>)
        })} */}
        <div className="drop-words animate__animated animate__fadeIn"> 
          {text}
        </div>
    </ div>
  )
}

export default DropLetters