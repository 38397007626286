import React from 'react'
import sns from '../helper/Socials.jsx'

const Footer = () => {
  return (
    <div className="footer">
      <div className="sns">
        {sns.map((social) => {
          return (
            <a className="social" key={social.name} href={social.link} target="_blank">
              {/* <img className={social.name} src={social.img} /> */}
              {social.svg}
            </a>
          )
        })}
      </div>
      <div className="copyright">
        <p>Crafted with&nbsp;</p><span className="juice">🧃</span><p>&nbsp;by Civic DX</p>
      </div>
      <div>

      </div>
    </div>
  )
}

export default Footer