const manitext = [ 
    {
        id: 1,
        text: "We are building a 100-year company. We are building a 100-year company.",
        highlight: "building",
        defintion: "To construct (something) by putting parts or material together. To make stronger or more intense.",
    },
    {
        id: 2,
        text: "We are charging storms, not running from them.",
        highlight: "charging",
        defintion: "To rush forward aggressively in attack.",
    },
    {
        id: 3,
        text: "We are empowering teammates to perform the best work of their lives.",
        highlight: "empowering",
        defintion: "To make (someone) stronger and more confident, especially in controlling their life and claiming their rights.",
    },
    {
        id: 4,
        text: "We are encouraging a culture that values health, inclusiveness, and diversity of thought over bottom-line thinking. ",
        highlight: "encouraging",
        defintion: "To inspire (someone) with the courage or confidence (to do something). To stimulate by approval.",
    },
    {
        id: 5,
        text: "We are cultivating an office environment that inspires our teammates and community. ",
        highlight: "cultivating",
        defintion: "To acquire or develop (a quality, sentiment, or skill).",
    },
    {
        id: 6,
        text: "We are redefining the enterprise sales and buying experience.",
        highlight: "redefining",
        defintion: "To define again or differently. To reformulate.",
    },
    {
        id: 7,
        text: "We are delighting customers in unknown ways. ",
        highlight: "delighting",
        defintion: "To provide great pleasure. To give joy or satisfaction.",
    },

]

export default manitext;