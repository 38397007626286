import React from 'react'
import "pure-react-carousel/dist/react-carousel.es.css";
import reviews from '../helper/ReviewList.jsx';
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import "@egjs/flicking-plugins/dist/pagination.css";
import { Pagination } from "@egjs/flicking-plugins";



const Reviews = () => {
    const plugins = [new Pagination({ type: 'bullet' })];

  return (<>
  <Flicking circular={true} plugins={plugins} deceleration={2} inputType={["touch", "mouse"]}>
    {reviews.map((review, index) => {
        return (
            <div key={index} className="card-panel">
                <div className="review-header">
                    <div className="review-header__text">
                        <h1>{review.name}</h1>
                        <h3>{review.title}</h3> 
                    </div>
                </div>
                <div className="review-header__image">
                    {/* <img className="triangle" src={review.img}></img> */}
                    <img className="circular_image" src={review.img} />
                    {/* <div className="review-header__text">
                        <h1>{review.name}</h1>
                        <h3>{review.title}</h3> 
                        <img className="review-header__logo" src={review.logo} />
                    </div> */}
                </div>
                <div className="qt-container">
                    <div className="qt">
                        <div className="">
                            <img className="qt-begin" src="assets/quote_begin.svg" />
                            <p className="review-quote">{review.quote}</p>
                            {/* <img className="qt-end" src="assets/quote_end.svg" /> */}
                        </div>
                    </div>
                </div>
                <div className="logo__container">
                    <img className="review-header__logo" src={review.logo} />
                </div>
            </div>
        )
    })}
      <ViewportSlot>
        <div className="flicking-pagination"></div>
      </ViewportSlot>
    </Flicking>
  </>
  )
}

export default Reviews