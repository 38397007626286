import React, { useState, useEffect, useCallback } from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import useWindowDimensions from '../../hooks/windowDimensions';
import { useLocation } from 'react-router-dom';
import BackToTop from '../BackToTop';

const HomeLayout = ({ children }) => {
  const [y, setY] = useState(window.scrollY);

  const [direction, setDirection] = useState(true);
  const location = useLocation();
  const { width, height } = useWindowDimensions();

  const emoteList = ["🕛", "🕐", "🕑", "🕒", "🕓", "🕔", "🕕", "🕖", "🕗", "🕘", "🕙", "🕚"];
  // const emoteList = ["🔴", "🔵", "⚪"]
  const [looping, setLooping] = useState(false)
  const [itemKey, setItemKey] = useState(0)

  useEffect(() => {
    scrollToTop()
  }, [location])

  useEffect(() => {
    let timer;
    if (looping) {
      timer = setInterval(() => setItemKey((i) => (i + 1) % emoteList.length), 500)
      document.title = `Click Me ${emoteList[itemKey]} | Civic DX`
      return () => clearInterval(timer);
    } else {
      clearInterval(timer)
      setItemKey(0)
    }
  }, [looping, itemKey])

  const onBlur = () => {
    setLooping(true)
  };

  const onFocus = () => {
    setLooping(false)
    document.title = "Civic DX"
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);


  const scrollToTop = (isSmooth) => {
    if (isSmooth) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "instant"
      });
    }
  };

  const handleScroll = useCallback(
    e => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        // console.log("scrolling up");
        setDirection(true)
      } else if (y < window.scrollY) {
        // console.log("scrolling down");
        setDirection(false)
      }
      setY(window.scrollY);
    }, [y]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);


  useEffect(() => {
    document.getElementById("root").style.height = 'unset'
    document.getElementById("root").style.minHeighteight = 'unset'
  }, [])

  return (
    <div className="home-layout">
      {width > 300 && height < 500 ?

        <div className="landscape-mode">
          <h1>Please turn your phone portrait mode.</h1>
          <div className="landscape-svg-container">
            <svg id="west" width="58" height="67" viewBox="0 0 58 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M54.5394 1.5L28.6481 41.6711L2.75165 1.5H54.5394Z" stroke="#00356B" strokeWidth="3" />
              <path d="M54.5394 23.5L28.6481 63.6711L2.75165 23.5H54.5394Z" stroke="#A41034" strokeWidth="3" />
            </svg>
            <svg id="east" width="58" height="67" viewBox="0 0 58 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M54.5394 1.5L28.6481 41.6711L2.75165 1.5H54.5394Z" stroke="#00356B" strokeWidth="3" />
              <path d="M54.5394 23.5L28.6481 63.6711L2.75165 23.5H54.5394Z" stroke="#A41034" strokeWidth="3" />
            </svg>
          </div>
        </div>

        : <>
          <Navbar scrollToTop={scrollToTop} direction={direction} />
          <div className="main">
            <BackToTop />
            {children}
          </div>
          <Footer />
        </>}
    </div>
  );
};

export default HomeLayout;