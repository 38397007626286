import React, { useState, useEffect, useLayoutEffect, useCallback, useRef } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import logos from '../../../helper/BrandLogos.jsx'
import casestudy from '../../../helper/CaseStudies.jsx'
import Masonry from 'react-masonry-css'
import Reviews from '../../../components/Reviews.jsx'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import MagneticButton from '../../../components/MagneticButton.jsx';
import useWindowDimensions from '../../../hooks/windowDimensions';
import useOnScreen from '../../../hooks/useOnScreen';

const Home = () => {
    const navigate = useNavigate();
    const [y, setY] = useState(window.scrollY);
    const [maxY, setMaxY] = useState(window.scrollMaxY);
    const containerRef = useRef(null);
    const containerRef2 = useRef(null);
    const textRef = useRef(null);
    const topTRef = useRef(null);
    const botTRef = useRef(null);
    const { height, width } = useWindowDimensions();
    const isVisible = useOnScreen(textRef);
    const topT = useOnScreen(topTRef);
    const botT = useOnScreen(botTRef);
    // const [maxMath, setMaxMath] = useState(0);
    const location = useLocation();

    // const half = width > 960 ? 75 : 80
    // const halfU = width > 960 ? "dvh" : "dvw"

    useEffect(() => {
        // console.log(containerRef.current.getBoundingClientRect())
        // console.log(containerRef2.current.getBoundingClientRect())
        setMaxY(containerRef2.current.getBoundingClientRect().height-24)
    }, [width, height])

    const handleScroll = useCallback (
        e => {
            // console.log(y, maxY, maxMath)
          const window = e.currentTarget;
        //   setMaxY(window.scrollMaxY);
            setY(window.scrollY);
            // setMaxMath(Math.min(y, maxY))
        }, [y]
      );
      
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
        setMaxY(containerRef2.current.getBoundingClientRect().height-24)
    }, [location.pathname])

    // console.log(y, maxY)

    const breakpointColumnsObj = {
        default: 2,
        960: 1,
      };

      const wwdRef = useRef(null);
      const handleWwdClick = () => {
        wwdRef.current.scrollIntoView({ behavior: 'smooth'});
      }

    return (
    <div className="home">
        <div className="landing-container">
            <div className="landing">
                    <div className="header animate__animated animate__fadeIn" style={{ whiteSpace: "pre-line" }}>
                        <h1>Digital expertise to power your innovation journey.</h1>
                        <h2>Passionate designers and developers, crafting impactful solutions for your business.</h2>
                    </div>
                    <a className="arrow" onClick={handleWwdClick}>
                    <svg width="58" height="134" viewBox="0 0 58 134" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="dblue-arrow" d="M54.5394 1.5L28.6481 41.6711L2.75165 1.5H54.5394Z" stroke="#00356B" strokeWidth="3"/>
                        <path id="red-arrow" d="M54.5394 23.5L28.6481 63.6711L2.75165 23.5H54.5394Z" stroke="#A41034" strokeWidth="3"/>
                    </svg>     
                    </a>
            </div>

            <div ref={containerRef2} className="logo-scroll-container">
                <div style={{transform: `translateY(calc(-${maxY}px + (${maxY}px * (${Math.min(y, maxY)/maxY}))))`}} className="ls-center">
                    <div ref={containerRef} className="logo-scroll">
                    {/* <div style={{transform: `translateY(${maxMath/2}px)`}} ref={containerRef} className="logo-scroll"> */}
                            <div style={{transition: "opacity 0.5s ease-in-out", opacity: `${(Math.min(y, maxY)/maxY) > .97 ? "1" : "0"}`}} className="bg"></div>
                            <div style={{opacity: `${Math.min(y, maxY)/maxY}`, transform: `translateY(calc((50dvw - ${Math.min(y, maxY)/maxY * 50}dvw) - 45%`}} className="bot"></div>
                            <div style={{opacity: `${Math.min(y, maxY)/maxY}`, transform: `translateX(calc(${Math.min(y, maxY)/maxY * 50}dvw - 50dvw)) translateY(-45%)`}} className="c"></div>
                            <div style={{opacity: `${Math.min(y, maxY)/maxY}`, transform: `translateX(calc(50dvw - ${Math.min(y, maxY)/maxY * 50}dvw)) translateY(-45%)`}} className="stripes"></div>
                    </div>
                    
                    {/* <div ref={textRef} style={{opacity: `${Math.min(y, maxY)/maxY}`, transform: `scale3d(${Math.min(y, maxY)/maxY * 1}, ${Math.min(y, maxY)/maxY * 1}, ${Math.min(y, maxY)/maxY * 1})`}} className="cdxtext-container"> */}
                    <div ref={textRef} style={{opacity: `${Math.min(y, maxY)/maxY}`, transform: `translateY(calc(100dvh - (100dvh * (${Math.min(y, maxY)/maxY})))) scale3d(${Math.min(y, maxY)/maxY * 1}, ${Math.min(y, maxY)/maxY * 1}, ${Math.min(y, maxY)/maxY * 1})`}} className="cdxtext-container">
                        <svg className="cdxtext" id="dash" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1342.6 237.53">
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_8" data-name="Layer 8">
                                    <path style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" d="M201.24,237.53H56.37a60.17,60.17,0,0,1-12-1.31,62,62,0,0,1-12.66-4.09,58.69,58.69,0,0,1-12-7.19A48.72,48.72,0,0,1,9.65,214.29a52.66,52.66,0,0,1-7-14.52A61,61,0,0,1,0,181.16V72.43a60.33,60.33,0,0,1,1.31-12A61.46,61.46,0,0,1,5.41,47.8a56.82,56.82,0,0,1,7.25-12A50.59,50.59,0,0,1,23.4,25.71a53.13,53.13,0,0,1,14.44-7,60.52,60.52,0,0,1,18.53-2.63H201.24V56.22H56.37q-7.87,0-12,4.17T40.15,72.74V181.16q0,7.72,4.25,12t12,4.25H201.24Z"/>
                                    <rect style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" x="229.04" y="70.58" width="40.15" height="166.95"/>
                                    <polygon style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" points="269.2 0 229.04 0 249.12 36.29 269.2 0"/>
                                    <path style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" d="M483.8,70.58l-76.58,156a19.54,19.54,0,0,1-7.34,8,20,20,0,0,1-10.73,3,19.7,19.7,0,0,1-10.58-3,20.56,20.56,0,0,1-7.49-8l-76.47-156h44.8l49.74,101.93L439.08,70.58Z"/>
                                    <rect style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" x="509.21" y="70.58" width="40.15" height="166.95"/>
                                    <polygon style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" points="549.37 0 509.21 0 529.29 36.29 549.37 0"/>
                                    <path style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" d="M743.2,237.53H634.31a64.58,64.58,0,0,1-18.22-2.93,53.28,53.28,0,0,1-32.35-27.11q-5.64-10.73-5.64-26.33V126.8a59.06,59.06,0,0,1,1.31-12,61.81,61.81,0,0,1,4.09-12.59,59.09,59.09,0,0,1,7.18-12,47.48,47.48,0,0,1,10.66-10,54.2,54.2,0,0,1,14.44-7,60.57,60.57,0,0,1,18.53-2.63H743.2v40.15H634.31q-7.87,0-12,4t-4.1,12.36v54.05q0,7.88,4.17,12.05t12.2,4.17H743.2Z"/>
                                    <path style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" d="M1085.06,71.58A86.92,86.92,0,0,0,1036.34,23a96.63,96.63,0,0,0-36.91-7H870.78V237.53H999.43a96.63,96.63,0,0,0,36.91-6.95,87.1,87.1,0,0,0,48.72-48.49,96.35,96.35,0,0,0,7-37.22V108.73A96.54,96.54,0,0,0,1085.06,71.58Zm-33.12,73.29a58.59,58.59,0,0,1-3.79,21.54,47,47,0,0,1-10.73,16.53,48.42,48.42,0,0,1-16.6,10.65,58,58,0,0,1-21.39,3.79h-88.5V56.22h88.5A58.24,58.24,0,0,1,1020.82,60a48.44,48.44,0,0,1,16.6,10.66,46.88,46.88,0,0,1,10.73,16.6,58.82,58.82,0,0,1,3.79,21.47Z"/>
                                    <path style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" d="M1220.13,95.14l67.64-79.08h54.83l-95.29,111.05L1342,237.53h-54.51l-67.34-78.46-67,78.46h-54.67L1193,127.11l-94.83-111h54.36Z"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
{/* 
            <div className="logo-scroll-container">
                <div style={{transform: `translateY(${maxMath}px)`}} ref={containerRef} className="logo-scroll">
                    <div style={{transition: "opacity 0.5s ease-in-out", opacity: `${(Math.min(y, maxY)/maxY) > .97 ? "1" : "0"}`}} className="bg"></div>
                    <div style={{opacity: `${Math.min(y, maxY)/maxY}`, transform: `translateY(calc(50dvw - ${Math.min(y, maxY)/maxY * 50}dvw`}} className="bot"></div>
                    <div style={{opacity: `${Math.min(y, maxY)/maxY}`, transform: `translateX(calc(${Math.min(y, maxY)/maxY * 50}dvw - 50dvw`}} className="c"></div>
                    <div style={{opacity: `${Math.min(y, maxY)/maxY}`, transform: `translateX(calc(50dvw - ${Math.min(y, maxY)/maxY * 50}dvw`}} className="stripes"></div>
                </div>
            
                <div ref={textRef} style={{opacity: `${Math.min(y, maxY)/maxY}`, transform: `translateY(-${Math.min(y, maxY)/maxY * half/2}${halfU}) scale3d(${Math.min(y, maxY)/maxY * 1}, ${Math.min(y, maxY)/maxY * 1}, ${Math.min(y, maxY)/maxY * 1})`}} className="cdxtext-container">
                    <svg className="cdxtext" id="dash" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1342.6 237.53">
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_8" data-name="Layer 8">
                                <path style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" d="M201.24,237.53H56.37a60.17,60.17,0,0,1-12-1.31,62,62,0,0,1-12.66-4.09,58.69,58.69,0,0,1-12-7.19A48.72,48.72,0,0,1,9.65,214.29a52.66,52.66,0,0,1-7-14.52A61,61,0,0,1,0,181.16V72.43a60.33,60.33,0,0,1,1.31-12A61.46,61.46,0,0,1,5.41,47.8a56.82,56.82,0,0,1,7.25-12A50.59,50.59,0,0,1,23.4,25.71a53.13,53.13,0,0,1,14.44-7,60.52,60.52,0,0,1,18.53-2.63H201.24V56.22H56.37q-7.87,0-12,4.17T40.15,72.74V181.16q0,7.72,4.25,12t12,4.25H201.24Z"/>
                                <rect style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" x="229.04" y="70.58" width="40.15" height="166.95"/>
                                <polygon style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" points="269.2 0 229.04 0 249.12 36.29 269.2 0"/>
                                <path style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" d="M483.8,70.58l-76.58,156a19.54,19.54,0,0,1-7.34,8,20,20,0,0,1-10.73,3,19.7,19.7,0,0,1-10.58-3,20.56,20.56,0,0,1-7.49-8l-76.47-156h44.8l49.74,101.93L439.08,70.58Z"/>
                                <rect style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" x="509.21" y="70.58" width="40.15" height="166.95"/>
                                <polygon style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" points="549.37 0 509.21 0 529.29 36.29 549.37 0"/>
                                <path style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" d="M743.2,237.53H634.31a64.58,64.58,0,0,1-18.22-2.93,53.28,53.28,0,0,1-32.35-27.11q-5.64-10.73-5.64-26.33V126.8a59.06,59.06,0,0,1,1.31-12,61.81,61.81,0,0,1,4.09-12.59,59.09,59.09,0,0,1,7.18-12,47.48,47.48,0,0,1,10.66-10,54.2,54.2,0,0,1,14.44-7,60.57,60.57,0,0,1,18.53-2.63H743.2v40.15H634.31q-7.87,0-12,4t-4.1,12.36v54.05q0,7.88,4.17,12.05t12.2,4.17H743.2Z"/>
                                <path style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" d="M1085.06,71.58A86.92,86.92,0,0,0,1036.34,23a96.63,96.63,0,0,0-36.91-7H870.78V237.53H999.43a96.63,96.63,0,0,0,36.91-6.95,87.1,87.1,0,0,0,48.72-48.49,96.35,96.35,0,0,0,7-37.22V108.73A96.54,96.54,0,0,0,1085.06,71.58Zm-33.12,73.29a58.59,58.59,0,0,1-3.79,21.54,47,47,0,0,1-10.73,16.53,48.42,48.42,0,0,1-16.6,10.65,58,58,0,0,1-21.39,3.79h-88.5V56.22h88.5A58.24,58.24,0,0,1,1020.82,60a48.44,48.44,0,0,1,16.6,10.66,46.88,46.88,0,0,1,10.73,16.6,58.82,58.82,0,0,1,3.79,21.47Z"/>
                                <path style={{animation: isVisible ? "stroke 2s" : ""}} className="cls-1" d="M1220.13,95.14l67.64-79.08h54.83l-95.29,111.05L1342,237.53h-54.51l-67.34-78.46-67,78.46h-54.67L1193,127.11l-94.83-111h54.36Z"/>
                            </g>
                        </g>
                    </svg>
                </div>
            </div> */}
        </div>

        <div ref={wwdRef} className="two-part wwd">
        <div className="left-text wwd">
            <h1>What We Do</h1>
            <p>Civic Digital Experience Inc. (“Civic DX”) is a technology boutique based in Atlanta with a driving passion to deliver enterprise-grade digital experiences alongside next-level design services.​</p>
            <p>We work strategically with large organizations and emerging brands to create unique and impactful digital services and products, whether they be in the cloud, desktop, or mobile tech spaces.​​</p>
            <p>A major cornerstone of our organization is the decades of experience we leverage to launch unique customer experiences for diverse industries such as government, construction, health and wellness, collegiate and professional athletics, and education.</p>
        </div>
        <div className="wwd-img-container">
            <div className="triangle-container">
                <div className="hg">
                    <AnimationOnScroll animateOnce={true} animateIn={width < 770 ? `animate__fadeInLeftBig` : 'animate__fadeInDownBig'}>
                        <img ref={topTRef} className="one" src='/assets/hg-t.jpg' />
                    </AnimationOnScroll>
                    <AnimationOnScroll animateOnce={true} delay={200} offset={0} animateIn={width < 770 ? `animate__fadeInRightBig` :'animate__fadeInUpBig'}>
                        <img ref={botTRef} className="two" src='/assets/hg-b.jpg' />
                    </AnimationOnScroll>
                </div>
                <img style={{opacity: (topT && botT) ? "1" : "0"}} className="thr" src='/assets/triangle/dia-img.jpg' />
            </div>
        </div>
    </div>
        
       <div className="carousel-container">
            <div className="carousels">
                {logos.map((logo, index) => {
                    return (<img key={index} className="logo-item" alt={logo.name} src={logo.img} />)
                })}
            </div>
            <div className="carousels">
                {logos.map((logo, index) => {
                    return (<img key={index+logo.name} className="logo-item" alt={logo.name} src={logo.img} />)
                })}
            </div>
       </div>
        {/* <div className="masonry-container">
            <Masonry 
            breakpointCols={breakpointColumnsObj} 
            className="my-masonry-grid" 
            columnClassName="my-masonry-grid_column">
            <div className="empty-grid-item"></div>
            {casestudy.slice(0,4).map((caseS, index) => {
                return (
                    <AnimationOnScroll animateOnce={true} key={index+caseS.title} animateIn={index % 2 ? "animate__fadeInLeft" : "animate__fadeInRight"} delay={`${index * 100}`}>
                        <div className="portfolio-box">
                            <img src={caseS.image}></img>
                                <div onClick={() => navigate("showcase/" + caseS.showcaseId)} className="portfolio-hover">
                                    <div className={`portfolio-title ${caseS.theme}`}>
                                        <h3 style={{"--max-height":"20px"}}>{caseS.title}</h3>
                                        <h1>{caseS.subtitle}</h1>
                                        <h3 style={{"--max-height":"4rem"}} key={index} className="services">
                                            {
                                                caseS.info.filter((item) => item.head === "Services")[0].items.join(", ")
                                            }
                                            &nbsp;•&nbsp;
                                            {
                                                caseS.info.filter((item) => item.head === "Product Type")[0].items.join(", ")

                                            }
                                        </h3>
                                    </div>
                                </div>
                        </div>
                    </AnimationOnScroll>
                )
            })}
            <AnimationOnScroll animateOnce={true} animateIn="animate__bounceInRight" offset={0} delay={100}>
                <div className="portfolio-center">
                    <a className="btn portfolio-btn v-hover" onClick={() => navigate("/showcase")}>
                        All cases
                        <img src="/assets/arrows/arrows-right.svg" />
                    </a>
                </div>
            </AnimationOnScroll>
            </Masonry>
        </div> */}

        <Reviews />

        {/* <AnimationOnScroll animateOnce={true} animateIn='animate__flipInX'> */}
            <div className="ending-container">
                <div className="ending">
                    <div className="ending-text">
                        <AnimationOnScroll animateOnce={true} animateIn='animate__fadeInLeft'>
                            <h1>Let's create</h1>
                            <h1>magic together.</h1>
                            <div className="ending-buttons">
                                <MagneticButton>
                                <a draggable="false" className="btn start-btn" onClick={() => navigate("/contact")}>
                                    Get Started
                                    <img src="/assets/arrows/arrows-right.svg" />
                                </a>
                                </MagneticButton>
                                <MagneticButton>
                                <a draggable="false" className="btn join-btn" href="https://www.linkedin.com/company/civicdx/jobs/" target="_blank">
                                    Join Us
                                    <img src="/assets/arrows/arrows-right-alt.svg" />
                                </a>
                                </MagneticButton>
                            </div>
                        </AnimationOnScroll>
                    </div>
                    <AnimationOnScroll animateOnce={true} animateIn='animate__fadeInRight'>
                        <img className="ending-triangle" src="/assets/cdx/CDX_Triangle_half.svg" />
                    </AnimationOnScroll>
                </div>
            </div>
        {/* </AnimationOnScroll> */}
    </div>
    )

}

export default Home;



