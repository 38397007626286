/*
    CAUTION: PLEASE READ! This File was auto generated by the system.
    Any changes you make will be removed the next time it's generated.
*/
import { ItemService } from 'spark-core-dx/services'

export const Operations = {
  JobPostingItem : {
     SelectDataJobStates: "SelectDataJobStates",
  },
}

export class ContactUsItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/ContactUs", coreState, coreContext, "APP", null, isWarning);
    }

}

export class JobApplicantItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/JobApplicant", coreState, coreContext, "APP", null, isWarning);
    }

}

export class JobCategoryItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/JobCategory", coreState, coreContext, "APP", null, isWarning);
    }

}

export class JobPostingItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/JobPosting", coreState, coreContext, "APP", null, isWarning);
    }

}

