import React, { useState } from 'react'
import reactStringReplace from 'react-string-replace';
import manitext from '../../../helper/ManifestoText.jsx'
import DropLetters from '../../../components/DropLetters.jsx';

const Manifesto = () => {
  const [popup, setPopup] = useState(false);
  const [selectedPopup, setSelectedPopup] = useState();
  const [anim, setAnim] = useState(true);

  const togglePopup = (id) => {
    setPopup(!popup)
    setSelectedPopup(id)
  }

  function rtrim(str) {
    if(!str) return str;
    return str.replace(/\s+$/g, '');
  }

  const lastIndexOf = (arr) => {
    for (let i = arr.length - 1; i >= 0; i--) { 
      if (arr[i].props.className === "highlight") {
        return i;
      } 
  } }

  const handleAnim = (id) => {
    setAnim(true)
    setSelectedPopup(id)
  }

  return (<>
    <div className="content manifesto">
      {manitext.map((text, index) => {
        
        const textList = reactStringReplace(text.text, text.highlight, (match, i) => (
          <span className="highlight">{match}</span>
        ))

        const updatedList = textList.map((tx) => {
          if (typeof tx === 'string' || tx instanceof String) {
            tx = <span className="regular">{rtrim(tx)}</span>
          }
            return (tx)
        })

        const fauxList = [...updatedList].concat([...updatedList]).concat([...updatedList])
        const cutItems = fauxList.slice(0, lastIndexOf(fauxList) - 4)
        const cutList = fauxList.slice(lastIndexOf(fauxList) - 4, fauxList.length);
        const finalList = [...cutList, ...cutItems].slice(0, 9)


        return (
          <div key={index} onTouchStart={() => handleAnim(text.id)} onMouseEnter={() => handleAnim(text.id)} onMouseLeave={() => setAnim(false)} className={anim && selectedPopup === text.id ? "carousel-container manifesto play" : "carousel-container manifesto"}>
              {popup && text.id === selectedPopup && (
                <div className="cover" onClick={() => setPopup(!popup)}>
                  <div className="popup-body animate__animated popupFadeIn">
                    <h1>{text.highlight.toUpperCase()}</h1>
                    <DropLetters text={text.defintion} />
                  </div>
                </div>)}
                {/* <div className="cover" style={{visibility: popup && text.id === selectedPopup ? "visible" : "hidden", opacity: popup && text.id === selectedPopup ? "1" : "0"}} onClick={() => setPopup(!popup)}>
                  <div className="popup-body animate__animated popupFadeIn"> 
                    <h1>{text.highlight.toUpperCase()}</h1>
                    <DropLetters text={text.defintion} />
                  </div>
                </div> */}
            {/* <div className="highlight-anim">{text.highlight}</div> */}
            <div className="center" onClick={() => togglePopup(text.id)}>
                {finalList[4]}
              <div className="left">
                {finalList[0]}{finalList[1]}{finalList[2]}{finalList[3]}
              </div>
              <div className="right">
                {finalList[5]}{finalList[6]}{finalList[7]}{finalList[8]}
              </div>
            </div>
            <div className='carousels manifesto'>
              {updatedList}
            </div>
            <div className='carousels manifesto'>
              {updatedList}
            </div>
          </div>
        )
      })}
      
    </div>
    <div className="letter">
        <div className="letter-header" style={{ whiteSpace: "pre-line" }}>
          <h1>Civic DX Brings the Juice</h1>
        </div>
        <div className="letter-text">
          <p>Anyone can take orders or sling code. Minimum viable responsibility meets minimum viable output, all the while masquerading as modern technology consulting. It is a safer choice for us to operate this way, especially if we want to look like the <i>big boys.</i>​</p>
          <p>However, we choose to run fast and far away from this approach. Perhaps we care more than we should sometimes. We love to solve big problems that impact businesses. We want to be bold, resourceful, and committed for our clients. </p>
          <p>We also want to do it with style. B2B software doesn’t have to be boring, cluttered, or painful. It has to get the job done, but why can’t it work like the other apps we regularly use in our personal life? </p>
          <p>We’re all about that <i>juice</i> – to bring energy and joy – so clients can experience a better world for their customers and themselves. </p>
          <br />
          <img className="signature" src="/assets/adsig.PNG" alt="Alan Dandar Signature" />
        </div>
    </div>
    </>
  )
}

export default Manifesto