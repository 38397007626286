const logos = [ 
    {
        name: "improving",
        img: "/assets/logos/improving.svg"
    },
    {
        name: "vertice-ai",
        img: "/assets/logos/vertice-ai.svg"
    },
    {
        name: "sidekick",
        img: "/assets/logos/sidekick.svg"
    },
    {
        name: "sld",
        img: "/assets/logos/sld.svg"
    },
    {
        name: "abm",
        img: "/assets/logos/abm.svg"
    },
    {
        name: "skybeam-capital",
        img: "/assets/logos/skybeam-capital.svg"
    },
    {
        name: "dover-custom",
        img: "/assets/logos/dovercustom.svg"
    },
    {
        name: "niet",
        img: "/assets/logos/niet_v3.svg"
    },
    // {
    //     name: "axon",
    //     img: "/assets/logos/axon.svg"
    // },
    {
        name: "capstone-logistics",
        img: "/assets/logos/capstone-logistics.svg"
    },
    {
        name: "zeezor",
        img: "/assets/logos/zeezor.svg"
    },
    {
        name: "gacp",
        img: "/assets/logos/gacp.svg"
    },
]

export default logos;