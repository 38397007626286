/*
    CAUTION: PLEASE READ! This File was auto generated by the system.
    Any changes you make will be removed the next time it's generated.
*/
import {
ContactUsItemService         
,JobApplicantItemService         
,JobCategoryItemService         
,JobPostingItemService} from './ItemServices';
import {ItemServiceFactory as BaseItemServiceFactory} from 'spark-core-dx/services'

export class ItemServiceFactory {

get ItemServiceEnum() {
  return {
      ...BaseItemServiceFactory.ItemServiceEnum,
    ContactUs:"ContactUs",
    JobApplicant:"JobApplicant",
    JobCategory:"JobCategory",
    JobPosting:"JobPosting",
  }
}

GetItemService(itemServiceName, coreState, coreContext, isWarning) {
    if(itemServiceName === undefined) throw new Error('Item service name not found, please regenerate the Item Services to resolve this issue.');
    let itemService;
    switch(itemServiceName.toLowerCase()){
        case this.ItemServiceEnum.ContactUs.toLowerCase():
             itemService = new ContactUsItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.JobApplicant.toLowerCase():
             itemService = new JobApplicantItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.JobCategory.toLowerCase():
             itemService = new JobCategoryItemService(coreState, coreContext, isWarning);
             break;
        case this.ItemServiceEnum.JobPosting.toLowerCase():
             itemService = new JobPostingItemService(coreState, coreContext, isWarning);
             break;
        default:
            itemService = BaseItemServiceFactory.GetItemService(itemServiceName, coreState, coreContext);
            break;
        }
        return itemService;
    }

ValidateItemName(itemName) {
    let isValid = false;
    for (const key in this.ItemServiceEnum){
        if (typeof(this.ItemServiceEnum[key]) === "string" &&
                this.ItemServiceEnum[key].toLowerCase() === itemName.toLowerCase())
                {
                    isValid = true;
                    break;
                }
        }
     return BaseItemServiceFactory.ValidateItemName(itemName);
 }
}
