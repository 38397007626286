import React from 'react'
import { useParams } from 'react-router-dom'
import casestudy from '../../../helper/CaseStudies.jsx'
import { useNavigate } from "react-router-dom";
import Flicking from "@egjs/react-flicking";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const CaseStudy = () => {
    const navigate = useNavigate();

    const { showcaseId } = useParams();
    const caseS = casestudy.find((ele) => {
        return ele.showcaseId === showcaseId
    })

    // const getNextPage = () => {
    //     if (casestudy.indexOf(caseS) + 1 === casestudy.length) {
    //         return `/${casestudy[0].showcaseId}`;
    //     } else {
    //         return `/${casestudy[casestudy.indexOf(caseS) + 1].showcaseId}`
    //     }
    // }

    // console.log(casestudy[getNextIndex()].showcaseId)
  return (
    <div className="content">
        <div className="portfolio-container">
            <div className="info-container animate__animated animate__fadeInUp">
                <div className="info-sections">
                    {/* <div className="casestudy-btns">
                        <a onClick={() => navigate('/showcase')} style={{marginBottom: "4rem"}}>
                            <img src='/assets/arrows/arrows-left.svg' />
                            <p>Back to Showcase</p>
                        </a>
                    </div> */}
                    {caseS.info.map((item, index) => {
                        return (
                            <div key={index} className="info-section">
                                <p className="info-title">{item.head}</p>
                                <div className="info-tags">
                                    {item.items.map((tag, ix) => {
                                        return (
                                            <span key={ix}>{tag}</span>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="info-buttons">
                    {/* <a className="btn"  onClick={() => navigate("#")}>
                        Testimonials
                        <img src="/assets/arrows-right.svg" />
                    </a> */}
                    <a className="btn v-hover"  onClick={() => navigate("/contact")}> {/* inverted-btn */}
                        Get Started
                        <img src="/assets/arrows/arrows-right.svg" />
                    </a>
                </div>
            </div>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight">
                <div className="article">
                    {/* <div className="casestudy-btns">
                        <a onClick={() => navigate('/showcase')}>
                            <img src='/assets/arrows/arrows-left.svg' />
                            <p>Back to Showcase</p>
                        </a>
                    </div> */}
                    <p className="article-subtitle">{caseS.subtitle}</p>
                    <h1 className="article-title">{caseS.title}</h1>
                    <h2 className="article-line">{caseS.line}</h2>
                    <Flicking className="info-container-mobile" moveType="freeScroll" circular={false} bound={true} deceleration={2} inputType={["touch", "mouse"]}>
                        {caseS.info.map((item, index) => {
                                    return (
                                        <div key={index} className="info-section">
                                            <p className="info-title">{item.head}</p>
                                            <div className="info-tags">
                                                {item.items.map((tag, ix) => {
                                                    return (
                                                        <span key={ix}>{tag}</span>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                    </Flicking>
                    {/* <div className="info-container mobile">
                        <div className="info-sections">
                            {caseS.info.map((item) => {
                                return (
                                    <div className="info-section">
                                        <p className="info-title">{item.head}</p>
                                        <div className="info-tags">
                                            {item.items.map((tag) => {
                                                return (
                                                    <span>{tag}</span>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div> */}
                {caseS.text}
                <div className="bts">
                    <a className="btn v-hover" onClick={() => navigate(`/showcase`)}>
                        <img style={{marginLeft: "0", marginRight: "0.5rem"}} src="/assets/arrows/arrows-left.svg" /> 
                        Back to Showcase
                    </a>
                </div>
                    <div className="info-buttons mobile">
                        <a className="btn"  onClick={() => navigate("/contact")}>
                            Get Started
                            <img src="/assets/arrows/arrows-right.svg" />
                        </a>
                        <a className="btn xv-hover" onClick={() => navigate(`/showcase`)}>
                            <img style={{marginLeft: "0", marginRight: "0.5rem"}} src="/assets/arrows/arrows-left.svg" /> 
                            Back to Showcase
                        </a>
                    </div>
                </div>
            </AnimationOnScroll>
        </div>
    </div>
  )
}

export default CaseStudy