import React, { useState, useEffect, useCallback } from 'react'
import { Outlet } from 'react-router-dom';
import { CareerDomainContextProvider } from './CareerDomainProvider';
import HomeLayout from '../../components/layouts/HomeLayout';
import './Careers.scss';
import _ from 'lodash';

//Loads screens/items dynamically.
//No custom, always 1 to 1.

const CareerDomain = () => {
    return (
        <CareerDomainContextProvider>
            <HomeLayout>
                <Outlet />
            </HomeLayout>
        </CareerDomainContextProvider>
    );
};

export default CareerDomain;