import React, { useState, useEffect, useCallback } from 'react'
import { Outlet } from 'react-router-dom';
import { HomeDomainContextProvider } from './HomeDomainProvider';
import HomeLayout from '../../components/layouts/HomeLayout';
import './Home.scss';
import _ from 'lodash';

//Loads screens/items dynamically.
//No custom, always 1 to 1.

const HomeDomain = () => {
    return (
        <HomeDomainContextProvider>
            <HomeLayout>
                <Outlet />
            </HomeLayout>
        </HomeDomainContextProvider>
    );
};

export default HomeDomain;