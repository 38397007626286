import React from 'react';
import { Route } from 'react-router-dom';
import { SparkApp } from 'spark-core-dx';
import { DomainRoute } from 'spark-core-dx/domains';
import Home from './domains/home/screens/Home';
import Manifesto from './domains/home/screens/Manifesto';
import Careers from './domains/career/screens/Careers';
import Job from './domains/career/screens/Job';
import Portfolio from './domains/home/screens/Portfolio';
import Contact from './domains/home/screens/Contact';
import CaseStudy from './domains/home/screens/CaseStudy';
import HomeDomain from './domains/home/HomeDomain';
// import Triangle from './components/Triangle';
import { ItemServiceFactory as CurrentItemServiceFactory } from './services/ItemServiceFactory';
import { useCoreStateContext, useCoreContext } from 'spark-core-dx/contexts';
import CareerDomain from './domains/career/CareerDomain';

const App = () => {
  const itemSvcFactory = new CurrentItemServiceFactory();

  // Reserved /login /logout /admin/* 
  return (
    <SparkApp
      itemServiceFactory={itemSvcFactory}
      asyncRoutes={[
        '/',
        '/manifesto',
        '/showcase',
        '/careers',
        '/careers/:jobId',
        '/contact',
        '/showcase/:showcaseId'
      ]}
      addonRoutes={[
        <Route key={"Home"} element={<DomainRoute component={HomeDomain} />} > {/* Domain */}
          <Route exact path={'/'} element={<Home />} /> {/* Screens */}
          <Route exact path={'/manifesto'} element={<Manifesto />} />
          <Route exact path={'/showcase'} element={<Portfolio />} />
          <Route exact path={'/contact'} element={<Contact />} />
          <Route path={'showcase/:showcaseId'} element={<CaseStudy />} />
          {/* <Route exact path={'/test'} element={<Triangle />} /> */}
        </Route>,
        <Route key={"Career"} element={<DomainRoute component={CareerDomain} />} > {/* Domain */}
          <Route exact path={'/careers'} element={<Careers />} />
          <Route path={'careers/:jobId'} element={<Job />} />
      </Route>
      ]} />
  )
}

export default App;
