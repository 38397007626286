import React from 'react'
import { useNavigate } from "react-router-dom";
import casestudy from '../../../helper/CaseStudies.jsx'
import Masonry from 'react-masonry-css'
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Portfolio = () => {
  const navigate = useNavigate();

  const breakpointColumnsObj = {
    default: 2,
    960: 1,
  };
  
  return (
    <div className="content">
      <div className="portfolio-text animate__animated animate__fadeInLeft">
        <h1>Showcase</h1>
        <p>Welcome to our showcase, where we unveil the technological prowess and innovation driving our solutions. Dive into a curated selection of our finest creations, crafted to empower businesses and transform industries.​</p>
      </div>
      <Masonry 
        breakpointCols={breakpointColumnsObj} 
        className="my-masonry-grid page" 
        columnClassName="my-masonry-grid_column">
        <div className="empty-grid-item"></div>
        {casestudy.map((caseS, index) => {
            return (
              <AnimationOnScroll animateOnce={true} key={index} offset={0} animateIn={index % 2 ? "animate__fadeInLeft" : "animate__fadeInRight"} delay={`${index * 100}`}>
                <div key={index} className={`portfolio-box animate__animated animate__fadeInUp`} index={caseS.key}>
                    <img src={caseS.image}></img>
                        <div onClick={() => navigate(caseS.showcaseId)} className="portfolio-hover">
                            {/* <div className="portfolio-title">
                                <h1>{caseS.title}</h1>
                                <h3>{caseS.subtitle}</h3>
                            </div> */}
                             <div className={`portfolio-title ${caseS.theme}`}>
                                <h3 style={{"--max-height":"20px"}}>{caseS.title}</h3>
                                <h1>{caseS.subtitle}</h1>
                                <h3 style={{"--max-height":"4rem"}} key={index} className="services">
                                    {
                                        caseS.info.filter((item) => item.head === "Services")[0].items.join(", ")
                                    }
                                    &nbsp;•&nbsp;
                                    {
                                        caseS.info.filter((item) => item.head === "Product Type")[0].items.join(", ")

                                    }
                                </h3>
                            </div>
                            {/* <a className="portfolio-link" onClick={() => navigate(caseS.showcaseId)}>
                                <img src="/assets/arrows/white-double-arrows.svg" />
                            </a> */}
                        </div>
                </div>
              </AnimationOnScroll>
            )
        })}
        </Masonry>
    </div>
  )
}

export default Portfolio